/* Left Navigation */

body {
  background: rgb(237 242 244 / 0.2);
}

.icon-drw {
  /* width: 36px; */
  width: 20px;
  margin-right: 20px;
}

/* new changes css start here  */

.swal2-styled.swal2-confirm {
  background-color: #d90429 !important;
}
.swal2-styled.swal2-cancel {
  background-color: #8d99ae !important;
}
.swal2-icon.swal2-success .swal2-success-ring {
  border: 0.25em solid rgb(141 153 174 / 30%) !important;
}
.swal2-icon.swal2-success [class^="swal2-success-line"] {
  background-color: #8d99ae !important;
}
.swal2-styled:focus {
  box-shadow: none !important;
}
.swal2-styled {
  padding: 5px 30px !important;
  border-radius: 20px !important;
}

.clasThmClr a {
  color: #2b2d42;
}

.customSwalBtn_ts {
  border-radius: 20px;
  padding: 5px 10px;
  margin: 5px;
  color: #ffffff;
  width: 50%;
}

.SwalBtn1_ts.customSwalBtn_ts {
  background: #d90429;
  margin-top: 15px;
}

.SwalBtn2_ts.customSwalBtn_ts {
  background: #d90429;
}

.SwalBtn3_ts.customSwalBtn_ts {
  background: #d90429;
}

.borderRadius-20 {
  border-radius: 20px !important;
}

.swal2-icon.swal2-info {
  border-color: #d90429 !important;
  color: #d90429 !important;
}

/* new changes css ends here  */

.svg__image {
  width: 28px;
}

.top__align__tool_nv {
  height: 56px;
}

.adjust-screens-bottom {
  height: 70px;
}

.empty-ticket-refresh {
  color: #d90429;
  padding: 0 12%;
  font-size: 20px;
  font-weight: bold;
  line-height: 27px;
}

.swal2-title {
  font-size: 16px;
}

.swal2-content {
  font-size: 14px;
}

.swal2-styled {
  padding: 5px 12px;
}

.swal2-popup {
  width: 300px;
}

/* signup page start*/

/* .mobile_input {
    margin-left: 40px;
} */

.profile-avatars > input {
  display: none;
}

.profile-avatars > input + img {
  cursor: pointer;
  border: 2px solid transparent;
}

.profile-avatars > input:checked + img {
  border: 2px solid red;
}

.mobile_input.sp {
  float: left;
  width: 78%;
  padding-left: 10px;
  font-size: 20px;
}
.selected-flag {
  /* position: absolute;
    top: 43px; */
  display: inline-block;
  width: 100%;
}

.selected-flag select {
  /* width: 75px; */
  color: #adadad;
  border: none;
  float: left;
  width: 20%;
  margin-top: 14px;
}

.selected-flag select:focus {
  border: none;
  outline: 0;
}

.required__field {
  color: #d90429;
}

.overlay {
  /* background-color: rgba(0, 0, 0, 0.2); */
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1000;
}

.otp-input {
  border-bottom: 1px solid #2b2d42;
  width: 24%;
  text-align: center;
  padding: 10px;
}
.w-100 {
  width: 100%;
}
input.otp-input:focus {
  border-color: #2b2d42 !important;
}

/* signup page End */

.cursorPointer {
  cursor: pointer;
}
.linkUnderline {
  text-decoration: underline;
}

/* ************************************* */
.login-bg-screen {
  background: url("../images/background.jpg");
}

header.cd-main-header.js-cd-main-header {
  background: #d90429;
}

a.float:hover {
  color: #ffffff;
}

.highlight__menu:focus {
  background: #2b2d42 !important;
}

.text-success_ {
  color: #d90429;
}

.text-info_ {
  color: #ffb300;
}

.text-danger_ {
  color: #f44336;
}

.sidebar {
  height: 100%;
  width: 85px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #272c32;
  /*transition: 0.5s;*/
  overflow-x: hidden;
  padding-top: 10px;
  white-space: nowrap;
}

.sidebar a {
  padding: 8px 8px 8px 20px;
  text-decoration: none;
  font-size: 14px;
  color: #ffffff;
  display: block;
}

.sidebar a:hover {
  color: #f1f1f1;
}

main .sidebar {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 14px;
  width: 200px;
}

.material-icons,
.icon-text {
  vertical-align: middle;
}

.material-icons {
  padding-bottom: 3px;
  font-size: 20px;
  color: #2b2d42;
}

.mr-18 {
  margin-right: 18px;
}

#main {
  padding: 0px;
  transition: margin-left 0.5s;
}

@media screen and (max-height: 450px) {
  .sidebar {
    padding-top: 15px;
  }

  .sidebar a {
    font-size: 14px;
  }
}

.cd-logo {
  width: 130px;
}

.btn-menu {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

.btn-menu i {
  color: #ffffff;
}

.cd-nav__item {
  margin-right: 20px !important;
}

.cd-side__label.cus {
  font-size: 11px;
  padding-left: 25px;
  padding-bottom: 10px;
  text-transform: uppercase;
  font-weight: bold;
  color: hsla(0, 0%, 100%, 0.4);
  color: hsla(
    var(--cd-color-3-h),
    var(--cd-color-3-s),
    var(--cd-color-3-l),
    0.4
  );
  letter-spacing: 0.1em;
}

.active {
  box-shadow: inset 3px 0 0 #d90429;
  box-shadow: inset 3px 0 0 #d90429;
  background-color: hsla(0, 0%, 100%, 0.05);
  background-color: hsla(
    var(--cd-color-3-h),
    var(--cd-color-3-s),
    var(--cd-color-3-l),
    0.05
  );
}

.cd-count {
  position: relative;
  margin-left: 25px;
  border-radius: 50%;
  background-color: #d90429;
}

.menu-logo {
  height: 85px;
  width: auto;
  padding: 10px;
}

.mystyle {
  margin-left: 0 !important;
}

.mystyle.mglf {
  margin-left: -145px !important;
}

button:focus {
  outline: none;
}

/* dashboard notification */

.rb-container {
  padding: 20px;
  width: 100%;
  margin: auto;
  display: block;
  position: relative;
}

.rb-container ul.rb {
  display: inline-block;
  padding-left: 0;
}

.rb-container ul.rb li {
  list-style: none;
  margin: auto;
  min-height: 50px;
  border-left: 1px dashed #99999a;
  padding: 0 0 15px 15px;
  position: relative;
}

.rb-container ul.rb li:last-child {
  border-left: 0;
}

.rb-container ul.rb li::before {
  position: absolute;
  left: -8px;
  top: 2px;
  content: " ";
  border: 6px solid #d90429;
  border-radius: 500%;
  background: #ffffff;
  height: 15px;
  width: 15px;
  transition: all 500ms ease-in-out;
}

.rb-container ul.rb li:hover::before {
  border-color: #232931;
  transition: all 1000ms ease-in-out;
}

ul.rb li .timestamp {
  color: #d90429;
  position: relative;
  width: 100%;
  font-size: 12px;
}

.item-title {
  color: #2b2d42;
  font-size: 10px;
}

.logo-color {
  color: #d90429;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.customlogo {
  height: 150px;
  width: auto;
}

/* input[maxlength="3"] {
    padding-left: 40px;
    letter-spacing: 60px;
} */

/* input[maxlength="3"]+svg line {
    stroke-dasharray: 35;
    stroke-dashoffset: -30;
} */

input[maxlength="4"] {
  padding-left: 30px;
  letter-spacing: 87px;
}

input[maxlength="4"] + svg line {
  stroke-dasharray: 30;
  stroke-dashoffset: -10;
}

.otpValue {
  padding-left: 30px;
  letter-spacing: 87px;
}

.customwidth {
  width: 100%;
}

.otp_field_custom {
  padding-left: 30px !important;
  letter-spacing: 15px !important;
  border: 1px solid !important;
  border-radius: 20px !important;
  padding: 5px;
  text-align: center !important;
  width: 70% !important;
}

.otp_field_custom:focus {
  border: 1px solid !important;
}

.custom-a {
  text-decoration: blink;
  font-size: 14px;
  border: 1px solid #c39a33;
  background: rgba(216, 43, 42, 0.8313725490196079);
  color: #ffffff;
  padding: 8px;
}

.wrap-login100-form-btn {
  border-radius: 20px;
}

.wrap-login100 {
  border-radius: 0px;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

@media (max-width: 450px) {
  .login100-form-btn {
    height: 45px;
    font-size: 14px;
  }

  .input100 {
    font-size: 14px;
  }

  .login100-form-title {
    font-size: 18px;
  }

  .customlogo {
    height: 120px;
    width: auto;
  }
}

.custom-btn {
  width: 49%;
  background: #d90429;
}

.custom-box-none {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  -o-box-shadow: none;
  -ms-box-shadow: none;
}

.custom-bg-none {
  background: none;
}

.custom-a-line-none {
  text-decoration: blink;
}

.custom-splash-img {
  height: auto;
  width: 50%;
  margin-top: 21%;
}

.custom-text-splash-img {
  height: auto;
  width: 100%;
}

.p-l-0 {
  padding-left: 0px !important;
}

.p-r-0 {
  padding-right: 0px !important;
}

.custom-span-splash {
  font-weight: 900;
  background: #d90429;
  padding: 5px 8px;
  color: #ffffff;
}

.custom-spacing-btn {
  padding: 10px 10px 10px 20px;
  color: #d90429;
  background: #ffffff;
}

@media (max-width: 1920px) and (min-width: 464px) {
  .mt-60 {
    margin-top: 60%;
  }
}

.custom-section {
  width: 100%;
}

.custom-div {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-35%, -50%);
}

@media (max-width: 769px) {
  .custom-div {
    transform: translate(-45%, -50%);
  }

  .custom-splash-img {
    margin-top: 45%;
    width: 100%;
  }
}

@media (max-width: 500px) {
  .custom-splash-img {
    margin-top: 55%;
    width: 100%;
  }
}

.custom-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/* Image animation */

.animate-left {
  position: relative;
  animation: animateleft 3s;
}

.animate-right {
  position: relative;
  animation: animateright 3s;
}

@keyframes animateright {
  from {
    right: -200px;
    opacity: 0;
  }

  to {
    right: 0;
    opacity: 1;
  }
}

@keyframes animateleft {
  from {
    left: -200px;
    opacity: 0;
  }

  to {
    left: 0;
    opacity: 1;
  }
}

.animate-fading {
  animation: fading 15s infinite;
}

@keyframes fading {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* bottom nav */

.left-nav {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 55px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  display: flex;
  overflow-x: auto;
}

.left-nav__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  min-width: 50px;
  overflow: hidden;
  white-space: nowrap;
  font-family: sans-serif;
  font-size: 13px;
  /*color: #444444;*/
  color: #2b2d42;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  transition: background-color 0.1s ease-in-out;
}

.left-nav__link:hover,
.left-nav__link:hover .material-icons {
  background-color: #eeeeee;
  color: #d90429;
  text-decoration: blink;
}

.left-nav__link--active {
  color: #d90429;
  /*    border-top: 2px solid;*/
}

.left-nav__icon {
  font-size: 18px;
}

@media (min-width: 800px) {
  .left-nav {
    display: none;
  }

  .mobile-menu {
    display: none;
  }
}

@media (max-width: 800px) {
  #menuClose,
  #menuOpen {
    display: none;
  }
}

.custom-ticket-grid {
  border-left: 2px solid #d90429;
  background: #f7f8fb;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.custom-ticket-grid:hover {
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.25), 0 4px 5px rgba(0, 0, 0, 0.22);
}

@media (max-width: 768px) {
  .custom-ticket-grid {
    margin-bottom: 4%;
  }
}

.ticket-grid-title {
  font-weight: bold;
  font-size: 14px;
  color: #2b2d42;
}

.ticket-grid-count {
  font-size: 20px;
  color: #2b2d42;
}

@media (max-width: 1920px) and (min-width: 768px) {
  .vl {
    border-left: 1px solid #ddddde;
    padding: 10px;
    height: 400px;
  }
}

.custom-revenue-grid {
  background: #f7f8fb;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.revenue-title {
  text-transform: uppercase;
  font-size: 10px;
  color: #89a0b9;
}

.revenue-amount {
  color: #3b3b3b;
  font-size: 20px;
}

.reset.cd-nav-trigger.js-cd-nav-trigger.btn-open {
  margin-left: 10px !important;
}

[data-drawer="true"] drawer-menu {
  background: #ffffff;
}

ul:not(.browser-default) {
  padding-left: 0;
  list-style-type: none;
}

ul:not(.browser-default) > li {
  list-style-type: none;
}

.sidenav li {
  float: none;
  line-height: 79px;
}

.sidenav .user-view {
  position: relative;
  padding: 32px 32px 0;
  margin-bottom: 8px;
}

.sidenav .user-view .circle {
  height: 64px;
  width: 64px;
}

.sidenav .user-view .circle,
.sidenav .user-view .name,
.sidenav .user-view .email {
  display: block;
}

.circle {
  border-radius: 50%;
}

.sidenav .user-view .name {
  margin-top: 16px;
  font-weight: 500;
}

.sidenav .user-view .name,
.sidenav .user-view .email {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.6px;
  font-family: "poppins", sans-serif;
}

.sidenav .user-view .background {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.sidenav .user-view .background img {
  height: 100%;
}

.sidenav .user-view > a {
  height: auto;
  padding: 0;
  color: #fff;
  text-decoration: none;
}

.sidenav li > a {
  /*    color: rgba(0,0,0,0.87);*/
  color: #8d99ae;
  display: block;
  font-size: 14px;
  font-weight: 600;
  height: 48px;
  line-height: 48px;
  padding: 0 32px;
  text-decoration: none;
}

.sidenav li > a > i,
.sidenav li > a > [class^="mdi-"],
.sidenav li > a li > a > [class*="mdi-"],
.sidenav li > a > i.material-icons {
  float: left;
  height: 48px;
  line-height: 48px;
  margin: 0 32px 0 0;
  width: 24px;
  /*    color: rgba(0,0,0,0.54);*/
  /*    color: rgba(170, 170, 170, 0.5);*/
  color: #8d99ae;
}

li.nv__menu {
  border-bottom: 1px solid rgba(170, 170, 170, 0.1);
}

.left-nav__link:focus {
  outline: none;
  text-decoration: none;
  background: none;
}

/*****Ticket*******/

.list-tkt {
  padding: 20px 10px;
  border-bottom: 2px solid #eee;
  margin: 0;
}

.left-sd-tkt P {
  font-size: 16px;
  margin-bottom: 0px;
  color: #2b2d42;
}

.left-sd-tkt P span {
  float: right;
  color: #777;
}

.ldetails.list_cases a {
  font-size: 12px;
  text-decoration: none;
}

.time-dte-font {
  font-size: 12px;
}

.mesg-env {
  /*display: inline-block;
            float: left;
            margin-right: 4%;
            line-height: 36px;
            width: 10%;
            border-radius: 50%;
            background: #eeeeee;
            border: 1px solid #eeeeee;*/

  display: inline-block;
  float: left;
  margin-right: 4%;
  padding: 10px 13px;
  border-radius: 50%;
  background: #eeeeee;
  border: 1px solid #eeeeee;
}

.mesg-env i {
  /*margin-left: 9px;*/
  color: #777;
}

.left-sd-tkt {
  /*float: left;*/
  /*width: 60%;*/
}

.right-sd-tkt {
  margin-left: 14%;
  margin-top: 2%;
}

.right-sd-tkt p {
  font-size: 13px;
  color: #777;
  margin-bottom: 0;
}

.tkt-sts {
  margin-left: 1%;
}

span.tkt-sts-icn {
  margin-left: 10px;
  float: right;
  font-size: 20px;
}

.ldetails.list_cases {
  padding-top: 5px;
}

.float {
  position: fixed;
  width: 55px;
  height: 55px;
  bottom: 85px;
  right: 25px;
  background-color: #d90429;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
}

.my-float {
  margin-top: 18px;
  font-size: 22px;
}

[data-drawer-backdrop]:before {
  opacity: 1 !important;
}

/****** Add Ticket *********/

.ad__tk__lft p {
  float: left;
  padding-left: 10px;
  font-family: "poppins", sans-serif;
}

.ad__tk__rgt .form-control {
  border: 1px solid #d3d3d4;
  border-radius: 0;
}

/****** Ticket Details *********/

.ticket__desc_status {
  background: #fff;
  padding: 20px 10px;
}

.ticket__desc {
  padding: 20px 10px;
}

.ticket__desc h6 {
}

.ticket__desc_decs {
  background: #ffffff;
  padding: 12px 10px;
}

/**** New Layout ******/

.custom-ticket-grid {
  border-left: 2px solid #d90429;
  background: #f7f8fb;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.custom-ticket-grid:hover {
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.25), 0 4px 5px rgba(0, 0, 0, 0.22);
}

@media (max-width: 768px) {
  .custom-ticket-grid {
    margin-bottom: 4%;
  }
}

.ticket-grid-title {
  font-weight: bold;
  font-size: 14px;
  color: #2b2d42;
}

.ticket-grid-count {
  font-size: 20px;
  color: #2b2d42;
}

@media (max-width: 1920px) and (min-width: 768px) {
  .vl {
    border-left: 1px solid #ddddde;
    padding: 10px;
    height: 400px;
  }
}

.custom-revenue-grid {
  background: #f7f8fb;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.revenue-title {
  text-transform: uppercase;
  font-size: 10px;
  color: #89a0b9;
}

.revenue-amount {
  color: #3b3b3b;
  font-size: 20px;
}

/* custom dashboard */

.customer-row {
  background: #ffffff;
  padding: 15px 10px;
}

.customer-ticket-grid {
  padding: 10px;
}

.customer-title {
  color: #2b2d42;
  font-weight: bold;
  margin-bottom: 2%;
}

.tech-ticket-title {
  color: #a5a5a5;
  font-size: 12px;
}

.customer-ticket-title {
  color: #a5a5a5;
  font-size: 14px;
}

.customer-icon {
  height: 25px;
  width: auto;
  margin-bottom: 5px;
}

.customer-notification {
  padding: 10px 20px;
}

select.input100:focus {
  outline: none;
}

select.input100 {
  border: none;
}

.bb-none {
  border-bottom: none;
}

.d-inline {
  display: inline-block;
}

.ml-5 {
  margin-left: 5%;
}

.ml-2 {
  margin-left: 2%;
}

.custom-notification-grid {
  background: #ffffff;
  padding: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.11), 0 2px 2px rgba(0, 0, 0, 0);
}

.notification-title {
  color: #2b2d42;
  font-weight: bold;
  font-size: 17px;
  margin-bottom: 1%;
}

.notification-description {
  color: #4a4a4a;
  font-size: 15px;
}

.notification-time {
  color: #aeaeae;
  font-size: 12px;
  margin-top: 3%;
}

.empty-ticket-img {
  height: 200px;
  width: auto;
}

.empty-ticket-title {
  color: #8d99ae;
  font-size: 22px;
}

.empty-ticket-refresh {
  color: #d90429;
  margin-top: 3%;
  font-size: 22px;
}

.custom-screen-middle {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.custom-profile-grid {
  background: #ffffff;
  padding: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.11), 0 2px 2px rgba(0, 0, 0, 0);
}

.profile-title {
  color: #2b2d42;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 2%;
}

.profile-description {
  color: #4a4a4a;
  font-size: 20px;
}

.custom-profile-form {
  background: #ffffff;
  padding: 20px;
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.11), 0 2px 2px rgba(0, 0, 0, 0);
}

.custom-signup-icon {
  color: #adadad;
  padding-right: 3%;
  padding-left: 1%;
  font-size: 20px;
}

/* Mobile Header */
.cd-nav__item {
  display: inline-flex;
}

.cd-nav__item__spann {
  float: right;
  margin-top: 10px;
  margin-right: 10px;
}

.dropbtn {
  color: #fff;
}

/* alert box */
.alert-box {
  max-width: 300px;
  min-height: 300px;
}

.alert-box .alert-icon {
  padding-bottom: 20px;
}

.custom-ps {
  background: #ffffff;
  padding: 15px 20px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.1);
}

.custom-ok-btn {
  margin-top: 3%;
  background: #d90429;
  border-color: #d9042933;
  padding: 5px 10%;
  color: #ffffff;
}

.success-checkmark {
  width: 80px;
  height: 95px;
  margin: 0 auto;
  margin-bottom: 10px;
}

.success-checkmark .check-icon {
  width: 80px;
  height: 80px;
  position: relative;
  border-radius: 50%;
  box-sizing: content-box;
  border: 4px solid #4caf50;
}

.success-checkmark .check-icon::before {
  top: 3px;
  left: -2px;
  width: 30px;
  transform-origin: 100% 50%;
  border-radius: 100px 0 0 100px;
}

.success-checkmark .check-icon::after {
  top: 0;
  left: 30px;
  width: 60px;
  transform-origin: 0 50%;
  border-radius: 0 100px 100px 0;
  animation: rotate-circle 4.25s ease-in;
}

.success-checkmark .check-icon::before,
.success-checkmark .check-icon::after {
  content: "";
  height: 100px;
  position: absolute;
  background: #ffffff;
  transform: rotate(-45deg);
}

.success-checkmark .check-icon .icon-line {
  height: 5px;
  background-color: #4caf50;
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 10;
}

.success-checkmark .check-icon .icon-line.line-tip {
  top: 46px;
  left: 14px;
  width: 25px;
  transform: rotate(45deg);
  animation: icon-line-tip 0.75s;
}

.success-checkmark .check-icon .icon-line.line-long {
  top: 38px;
  right: 8px;
  width: 47px;
  transform: rotate(-45deg);
  animation: icon-line-long 0.75s;
}

.success-checkmark .check-icon .icon-circle {
  top: -4px;
  left: -4px;
  z-index: 10;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: absolute;
  box-sizing: content-box;
  border: 4px solid rgba(76, 175, 80, 0.5);
}

.success-checkmark .check-icon .icon-fix {
  top: 8px;
  width: 5px;
  left: 26px;
  z-index: 1;
  height: 85px;
  position: absolute;
  transform: rotate(-45deg);
  background-color: #ffffff;
}

@keyframes rotate-circle {
  0% {
    transform: rotate(-45deg);
  }

  5% {
    transform: rotate(-45deg);
  }

  12% {
    transform: rotate(-405deg);
  }

  100% {
    transform: rotate(-405deg);
  }
}

@keyframes icon-line-tip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }

  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }

  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }

  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }

  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@keyframes icon-line-long {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }

  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }

  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }

  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #d90429;
  border-color: #d90429 transparent #d90429 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* counter */

#countdown {
  position: relative;
  margin: auto;
  margin-top: 35%;
  height: 40px;
  width: 40px;
  text-align: center;
  font-size: 40px;
  font-weight: 600;
}
.text-center.w-100.custom-screen-center {
  padding-top: 30px;
}
#countdown-number {
  color: #d90429;
  display: inline-block;
  line-height: 40px;
}
.custom-auth-no-style {
  color: #d90429;
  font-size: 30px;
  margin-top: 5%;
  font-weight: bold;
  letter-spacing: 3px;
}

.tickmarkClass {
  position: fixed;
  z-index: 10;
  right: 16px;
  top: 14px;
}

.tickmarkClass a i {
  color: #ffffff;
}

.launch-dwn-section {
  display: flex;
  margin-top: 30px;
  justify-content: center;
}

.launch-dwnl-btn {
  background: #d90429;
  width: 50%;
  color: #ffffff !important;
  cursor: pointer;
}

.launch-dwnl-btn,
.launch-dwnl-btn:hover {
  text-decoration: none;
}

.chat .chat-message {
  width: 45% !important;
}

.chat .chat-message button {
  margin-right: 50px !important;
}

.chat .chat-message label {
  display: none;
}
