.container-chat {
  margin: 0 auto;
  width: 100%;
}

.people-list {
  width: 260px;
  float: left;
}

.people-list .search {
  padding: 20px;
}

.people-list input {
  border-radius: 3px;
  border: none;
  padding: 14px;
  color: white;
  background: #6a6c75;
  width: 90%;
  font-size: 14px;
}

.people-list .fa-search {
  position: relative;
  left: -25px;
}

.people-list ul {
  padding: 20px;
  height: 770px;
}

.people-list ul li {
  padding-bottom: 20px;
}

.people-list img {
  float: left;
}

.people-list .about {
  float: left;
  margin-top: 8px;
}

.people-list .about {
  padding-left: 8px;
}

.people-list .status {
  color: #92959e;
}

li.clearfix {
  line-height: normal;
}
.chat {
  width: 95%;
  float: left;
  background: #f9f9f9 !important;
  /* background: #ff0000 !important; */
  border-radius: 20px;
  margin: 20px;
  color: #434651;
  overflow: hidden;
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.06);
}

.chat .chat-header {
  padding: 10px 20px;
  border-bottom: 2px solid white;
}

.chat .chat-header img {
  float: left;
}

11 .chat .chat-header .chat-about {
  float: left;
  padding-left: 10px;
  margin-top: 6px;
}

.chat .chat-header .chat-with {
  font-weight: bold;
  font-size: 16px;
}

.chat .chat-header .chat-num-messages {
  color: #92959e;
}

.chat .chat-header .fa-star {
  float: right;
  color: #d8dadf;
  font-size: 20px;
  margin-top: 12px;
}

.chat .chat-history {
  padding: 0px 30px 20px;
  border-bottom: 2px solid white;
  overflow-y: auto;
  /* height: 85vh; */
  height: 77vh;
}

.chat .chat-history .message-data {
}

.chat .chat-history .message-data-time {
  color: #a8aab1;
  padding-left: 6px;
  font-size: 14px;
}

.chat .chat-history .message {
  color: white;
  padding: 8px 20px;
  line-height: 25px;
  font-size: 14px;
  border-radius: 14px;
  margin-bottom: 10px;
  width: 60%;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.02);
}

.chat .chat-history .message:after {
  bottom: 100%;
  left: 7%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #edf2f4;
  border-width: 10px;
  margin-left: -10px;
}

.chat .chat-history .my-message {
  background: #ffffff;
  color: #1a1a1a;
}

.chat .chat-history .other-message {
  background: #d90429;
}

.chat .chat-history .other-message:after {
  border-bottom-color: #d90429;
  left: 93%;
}

.chat .chat-message {
  bottom: 0;
  position: fixed;
  right: 0;
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 50px;
}

.chat .chat-message textarea {
  background: #2f2f2f;
  color: #ffffff;
  border-radius: 20px;
  width: 60%;
  border: none;
  height: 60px;
  padding: 10px 15px;
  font: 14px/22px Poppins-regular;
  resize: none;
}

.chat .chat-message .fa-file-o,
.chat .chat-message .fa-file-image-o {
  font-size: 20px;
  color: gray;
  cursor: pointer;
}

.chat .chat-message button {
  float: right;
  color: #337ab7;
  font-size: 16px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
}

.chat .chat-message button:hover {
  color: #286090;
}

.online,
.offline,
.me {
  margin-right: 3px;
  font-size: 10px;
}

.online {
  color: #86bb71;
}

.offline {
  color: #e38968;
}

.me {
  color: #d90429;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.float-right {
  float: right;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.no-pad {
  padding-left: 0px;
  padding-right: 0px;
}

header.cd-main-header.js-cd-main-header {
  width: 100%;
}

.cd-main-header {
  position: fixed;
}

.custom-top-h5 {
  width: 100%;
  /*  text-align: center;*/
  /*  position: absolute;*/
  font-size: 14px;
}

span.message-data-name {
  font-size: 14px;
  color: #a8aab1;
}

.chat_mat_icn {
  font-size: 14px;
  color: #d90429;
  padding-left: 5px;
}
.send-cht-btn {
  background: hsl(0, 0%, 100%);
  padding: 3px 15px;
  border-radius: 20px;
  height: 50px;
  margin-left: 20px;
  margin-right: 40px;
  color: #ffffff !important;
}

.message.other-message.float-right.cht-immg {
  background: transparent;
}

.chat .chat-history .my-message.cht-immg {
  background: transparent;
}

.chat .chat-history .other-message.cht-immg:after {
  border-bottom-color: transparent !important;
}

.message.other-message.float-right.cht-immg > [data-rmiz-wrap="visible"],
[data-rmiz-wrap="hidden"] {
  float: right;
}

.image-preview-confirmation-box {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 30px;
}

.image-preview-confirmation-box .imf-cnfrm-icons {
  color: #edf2f4;
  border: 1px solid #d90429;
  padding: 3px 20px;
  border-radius: 20px;
  /* font-weight: 600; */
  background: #d90429;
}

.image-preview-confirmation-box .imf-cnfrm-icons.okay {
  float: right;
}

.image-preview-confirmation-box .imf-cnfrm-icons.cancel {
  float: left;
}

/* ---------------------Chat loading spinner--------------------- */

.typing-loader {
  display: none;
  width: 6px;
  height: 6px;
  -webkit-animation: line 1s linear infinite alternate;
  -moz-animation: line 1s linear infinite alternate;
  animation: line 1s linear infinite alternate;

  position: absolute;
  bottom: 15%;
  left: 45%;
}
.typing-loader.show {
  display: block;
}
@-webkit-keyframes line {
  0% {
    background-color: #d90429;
    box-shadow: 12px 0px 0px 0px #d90429, 24px 0px 0px 0px #d90429;
  }
  25% {
    background-color: #d90429;
    box-shadow: 12px 0px 0px 0px #d90429, 24px 0px 0px 0px #d90429;
  }
  75% {
    background-color: #d90429;
    box-shadow: 12px 0px 0px 0px #d90429, 24px 0px 0px 0px #d90429;
  }
}

@-moz-keyframes line {
  0% {
    background-color: #d90429;
    box-shadow: 12px 0px 0px 0px #d90429, 24px 0px 0px 0px #d90429;
  }
  25% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px #d90429, 24px 0px 0px 0px #d90429;
  }
  75% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px #d90429, 24px 0px 0px 0px #d90429;
  }
}

@keyframes line {
  0% {
    background-color: #d90429;
    box-shadow: 12px 0px 0px 0px #d90429, 24px 0px 0px 0px #d90429;
  }
  25% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px #d90429, 24px 0px 0px 0px #d90429;
  }
  75% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px #d90429, 24px 0px 0px 0px #d90429;
  }
}
