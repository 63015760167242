.bg-color {
  background: rgb(237 242 244 / 0.2) !important;
  overflow: hidden;
}
/* .wrap-input100{
    width: 70%;
} */
/* .wrap-login100-form-btn{
    width: 40%;
} */
.information-details {
  background: #ffffff;
  padding: 15px;
  width: 70%;
  margin-top: 5%;
}
.custom-bb-b {
  /* border-bottom: 1px solid #D90429; */
  margin-bottom: 8%;
}
.font-18 {
  font-size: 18px;
}

#otp {
  display: none;
}
#otp.show {
  display: block;
}
.login100-form-btn {
  height: 40px;
}
.authchat .authchat-message {
  width: 42%;
}
/* .authchat .authchat-history{
    height: 40vh;
} */

.authchat {
  width: 100%;
  float: left;
  background: #f2f5f8;
  color: #434651;
}

.authchat .authchat-header {
  padding: 10px 20px;
  border-bottom: 2px solid white;
}

.authchat .authchat-header img {
  float: left;
}

.authchat .authchat-header .authchat-about {
  float: left;
  padding-left: 10px;
  margin-top: 6px;
}

.authchat .authchat-header .authchat-with {
  font-weight: bold;
  font-size: 16px;
}

.authchat .authchat-header .authchat-num-messages {
  color: #92959e;
}

.authchat .authchat-header .fa-star {
  float: right;
  color: #d8dadf;
  font-size: 20px;
  margin-top: 12px;
}

.authchat .authchat-history {
  /* padding: 30px 30px 20px; */
  padding: 0px 30px 20px;
  border-bottom: 2px solid white;
  overflow-y: scroll;
  height: 85vh;
  /* padding-top: 15px; */
}

.authchat .authchat-history .message-data {
  margin-bottom: 15px;
}

.authchat .authchat-history .message-data-time {
  color: #a8aab1;
  padding-left: 6px;
  font-size: 14px;
}

.authchat .authchat-history .message {
  color: white;
  padding: 8px 20px;
  line-height: 25px;
  font-size: 14px;
  border-radius: 7px;
  margin-bottom: 10px;
  width: 90%;
  position: relative;
}

.authchat .authchat-history .message:after {
  bottom: 100%;
  left: 7%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #edf2f4;
  border-width: 10px;
  margin-left: -10px;
}

.authchat .authchat-history .my-message {
  background: #edf2f4;
  color: #2b2d42;
}

.authchat .authchat-history .other-message {
  background: #d90429;
}

.authchat .authchat-history .other-message:after {
  border-bottom-color: #d90429;
  left: 93%;
}

.authchat .authchat-message {
  padding: 7px 10px;
  bottom: 0;
  position: fixed;
  background: #eeeeee;
}

.authchat .authchat-message textarea {
  width: 100%;
  border: none;
  height: 60px;
  padding: 10px;
  font: 14px/22px "Lato", Arial, sans-serif;
  margin-bottom: 10px;
  resize: none;
}

.authchat .authchat-message .fa-file-o,
.authchat .authchat-message .fa-file-image-o {
  font-size: 16px;
  color: gray;
  cursor: pointer;
}

/* .authchat .authchat-message button {
    float: right;
    color: #337ab7;
    font-size: 16px;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
    font-weight: bold;
}

.authchat .authchat-message button:hover {
    color: #286090;
} */

.selected-flag.authlogin select {
  width: 15%;
  background: transparent;
  font-size: 20px;
}

.nochat-history-div {
  text-align: center;
  margin-top: 15%;
}

.fix-logo {
  margin-left: 1%;
}
.color-tmp-5 {
  color: #d90429 !important;
}
.fixme-footer {
  margin-top: 6%;
}

.content-area {
  h1 {
    font-size: 14px;
  }
  h2 {
    font-size: 60px;
    font-weight: 900;
  }
  h3 {
    font-size: 14px;
    color: #92959e;
  }
  img {
    cursor: pointer;
    user-select: none;
    transition: transform 0.3s ease;
  }
  img:hover {
    transform: scale(1.05);
  }
  ul {
    list-style: none;
    padding: 0rem;
  }
  .item {
    position: relative;
    display: block;
    margin: 0px !important;
    padding: 0px !important;
  }
  .li {
    color: #000000;
    margin-left: 20px;
    font-size: 18px;
    &::before {
      position: absolute;
      content: "L";
      left: 0;
      top: 0;
      text-align: center;
      color: #d90429;
      font-family: arial;
      transform: scaleX(-1) rotate(-35deg);
    }
  }
}
