.prof_hd.header__title__main {
    color: #ffffff;
    margin-left: 5%;
    font-size: 16px;
}

.profEditBtnPnc {
    color: #ffffff;
}

.backBtnClas {
    font-size: 22px;
    color: #fff;
    margin-left: 10px;
    margin-top: 4px;
}
.colorWhite {
    color: #ffffff;
}

#hide__container {
    display: none
}